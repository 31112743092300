.header {
	position: relative;
	overflow: hidden;
	&.-page-header{
		margin-bottom: 2rem;
	}
	@include min(1200px){
		&.-page-header{
			margin-bottom: 4rem;
		}
	}

	&__title {
		position: relative;
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
		padding-top: 1.5rem;
		z-index: 2;
		@include flex(df, jcsb, aic);
		flex-direction: column;
		@include min(1024px) {
			padding-bottom: 2.2rem;
			flex-direction: row;
		}
	}
	&__nav{
		@include min(600px) {
			@include flex(df, jcsb, aic);
			flex-direction: column;
			flex-grow: 1;
		}
		@include min(1024px) {
			flex-direction: row;
		}
	}
	&__bottom{
		@include flex(df, jcsb);
		flex-direction: column-reverse;
		&-halve{
			position: relative;
    		z-index: 5;
		}
		@include min(1024px) {
			flex-direction: row;
			align-items: center;
			&-halve{
				max-width: 50%;
				flex-grow: 1;
				&:first-of-type{
					padding-right: 3.1rem;
					padding-top: 5%;
				}
			}
		}
	}

	&__heading {
		color: #ffffff;
		line-height: 1;
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		padding-right: 90px;

		margin: {
			top: 0;
			bottom: 0;
			left: -3%;
		}

		font: {
			size: rem(28px);
			weight: bold;
		}
		@include min(600px){
			padding-right: 0;
		}
		@include min(1650px){
			margin-left: -6.7rem;
			.-page-header &{
				margin-left: -4.75rem;
			}
		}
	}

	&__logo {
		float: left;
		margin: 0 1em 0;
		font-size: 1rem;
		@include max(599px){
			width: 356px;
		}
	}

	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}
	&__nav-button {
		position: absolute;
		right: 2%;
		top: 14vw;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		@include min(500px) {
			top: 70px;
		}
		@include min(600px) {
			display: none;
		}

		@include link(&) {
			text-decoration: none;
		}

		> .hamburger {
			padding: 8px 7px;
			//border: 1px solid currentColor;
			border-radius: 5px;
		}

		.nav-button__title {
			font-size: rem(10px);
			margin: 0;
		}
	}
}

.social-networks {
	position: relative;
	z-index: 20;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	text-align: left;
	padding: 0 0 0 32px;
	margin: 0;
	flex-shrink: 0;
	&__heading{
		font-size: rem(13px);
		font-weight: 800;
		color: #565656;
		margin-bottom: 1rem;
		font-family: $font-body;
	}
	&__list{
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		gap: 1rem;
	}
	&__item {
		display: inline-flex;
		margin: 0 0;
	}

	&__link {
		display: inline-flex;
		svg{
			transition: fill .3s ease-in-out;
			fill: #c4c4c4;
		}
	}
	@include link-over(){
		svg{
			fill: $color-dark;
		}
	}
	@include max($nav-horizontal-burger-threshold - 1px) {
		transition: max-height 0.3s ease-out;
		max-height: 0;
		overflow: hidden;
		animation: menuFadeOut 0.1s ease-out 0s forwards;
		.header__nav--toggled &{
			max-height: 20rem;
			padding-bottom: 2.2rem;
			animation: menuFadeIn 0.3s ease-out 0s forwards;
		}
	}
	@include min(600px){
		position: absolute;
		right: 5rem;
		top: 1rem;
		margin: 0 0 0 3rem;
		align-items: center;
		flex-direction: row;
		align-items: center;
		text-align: right;
		padding-left: 0;
	}
	@include min(1360px){
		position: static;
		top: 0;
		flex-direction: column;
		align-items: flex-end;
	}
}
