@keyframes menuFadeIn {
	from {
		opacity: 0;
		transform: translateY(200%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes menuFadeOut {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(200%);
	}
}

.main-nav {
	overflow: visible;
	color: $color-dark;
	padding: 0;
	margin: 0 auto;

	.nav {
		@include flex(df, jcsa, fww, fdc);
		padding: 0;
		margin: 2em auto;
		gap: 1rem;
		@include min(1024px){
			margin: 1em auto 1em;
			gap: 0;
		}
		&__item {
			display: inline-block;
			flex-grow: 1;
			margin: 0;
			text-align: left;
			font-weight: 700;
			font-size: rem(19px);
			color: $color-dark;
			[class*="info-"]{
				vertical-align: top;
			}
		}

		&__link {
			position: relative;
			display: inline-flex;
			padding-bottom: 0;
			padding-left: 32px;
			overflow: hidden;
			align-items: center;
			&::after{
				content: "";
				display: inline-block;
				width: 16px;
				height: 11px;
				background: transparent url(/images/local/v-202503271058/main-nav-arrow.png) no-repeat right 50%;
				margin-left: 1rem;
				transition: background-position .1s ease-in;
			}
			&.-active {
				@include link(&){
					text-decoration: none;
					color: $color-brand;
					&::after{
						background-position: left;
					}
				}
			}
		}
		&__description{
			display: none;
		}
		@include link() {
			color: $color-dark;
			text-decoration: none;
		}

		@include link-over() {
			color: $color-brand;
			&::after{
				background-position: left;
			}
		}

		@include max($nav-horizontal-burger-threshold - 1px) {
			transition: max-height 0.3s ease-out;
			max-height: 0;
			overflow: hidden;
			&__item{
				animation: menuFadeOut 0.1s ease-out 0s forwards;
				opacity: 0;

				@for $li from 2 through 10 {
					$delay: (50 * ($li - 1));
					&:nth-of-type(#{$li}) {
						animation-delay: to-length($delay, "ms") !important;
					}
				}
			}

			.header__nav--toggled &{
				max-height: 20rem;

				&__item{
					animation: menuFadeIn 0.3s ease-out 0s forwards;
				}
			}
		}

		@include min(600px){
			@include flex(jcsa, fdr);
			&__item {
				text-align: center;
			}
		}
	}
}
