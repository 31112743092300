
.universal-popup-component {
	position: fixed;
	overflow: hidden;
	z-index: 90;
	left: -9999rem;
	width: 0;
	height: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: rem(18px);
	color: #323232;

	&::before {
		position: fixed;
		z-index: 1;
		opacity: 0;
		transition: opacity .15s ease-out;
	}

	&__form {
		position: relative;
		z-index: 2;
		transform: scale(0);
		background-color: #ffffff;
		border-radius: 15px;
		transition: transform .15s ease-out, box-shadow .2s ease-out;
		box-shadow: 0 0 0 rgba(#000000, 0);
		max-width: 80vw;
		max-height: 70vh;
		min-height: 10em;
		width: 36em;
		padding: 3vmax;
	}

	&__heading {
		font-family: inherit;
		color: inherit;
		margin: 0 0 .66em;
		padding: 0;
		text-transform: none;
		font-size: rem(35px);
	}

	&__row {
		display: flex;
		flex-direction: column;
		margin: 0;

		&:empty {
			display: none;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&.-end {
			align-items: flex-end;
		}

		&.-center {
			align-items: center;
		}
	}

	&__label {
		font-family: inherit;
		font-size: inherit;
		margin: 0 0 6px;
	}

	&__input {
		font-family: inherit;
		font-size: inherit;
		height: 54px;
		border-radius: 6px;
		border: 1px #cccccc solid;
		outline: 0;
	}

	&__button {
		font-family: inherit;
		font-size: inherit;
		border-radius: 5px;
		background-color: #888888;
		color: #ffffff;
	}

	&__close {
		font-size: rem(20px);
		font-weight: 600;
		position: absolute;
		right: 1em;
		top: 1em;
		line-height: 0;
		width: 20px;
		height: 20px;
		text-decoration: none;
	}

	//&:focus-within,
	&.-visible,
	&:target { //component is now visible
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		&::before {
			content: "";
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 1;
			background: rgba(#000000, .7);
		}

		@keyframes popup-bounce{
			0% {
				transform: scale(0);
			} 60% {
				transform: scale(1.1);
			} 100% {
				transform: scale(1);
			}
		}

		.universal-popup-component__form {
			z-index: 2;
			//transform: scale(1);
			animation: popup-bounce .3s ease-out;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			transform-origin: 50% 100%;
			box-shadow: 0 5px 20px rgba(#000000, .5);
		}
	}
}
