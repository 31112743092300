.breadcrumbs {
	text-align: right;
	color: #383838;

	.centered.-padded {
		padding-left: 0;
		padding-right: 0;
		padding-top: 2em;
		padding-bottom: 0;
		text-align: right;
		border-top: 1px solid #c4c4c4;
		font-size: rem(14px);
	}
}

.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;

	&__item {
		display: inline;


		&:not(.-active)::after {
			content: "";
			display: inline-block;
			width: 16px;
			height: 11px;
			margin: 0 0.75rem;
			background: transparent url(/images/local/v-202503271058/main-nav-arrow.png) no-repeat left 50%;
			vertical-align: middle;
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $color-base;
		text-decoration: underline;
	}

	@include link(".breadcrumb-nav__link.-active") {
		pointer-events: none;
		text-decoration: none;
		font-weight: 700;
		color: $color-dark;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
