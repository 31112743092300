
.contact {
	&__line {
		padding-left: 50px;
		margin: 0 0 1em;

		&.-location,
		&.-phone,
		&.-email {
			padding-top: .15em;
			padding-bottom: .15em;
			background: {
				repeat: no-repeat;
				position: 0 50%;
			}
		}

		&.-location {
			background-image: url(/images/local/v-202503271058/org-marker.png)
		}

		&.-phone {
			background-image: url(/images/local/v-202503271058/org-phone.png)
		}

		&.-email {
			background-image: url(/images/local/v-202503271058/org-mail.png)
		}
	}
}
