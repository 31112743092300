/*****************************************
* blackwell - FUNCTIONS
*****************************************/

@function rem($size) {
	$size: if(unitless($size), $size * 1px, $size);
	$remSize: ($size / 16px) * 1rem;

	@return $remSize;
}

@function ratio($x, $y, $returntype: percentage) {
	@if $returntype == percentage {
		@return ($y / $x) * 100%;
	} @else if $returntype == float {
		$ratio: $y / $x;
		@return strip-unit($ratio);
	} @else if $returntype == vw {
		$ratio: $y / $x;
		$ratio: $ratio * 100;
		@return to-length(strip-unit($ratio), vw);
	}
}

@function clamp-value($value, $min, $max) {
	@return if($value > $max, $max, if($value < $min, $min, $value));
}

@function dec-round($number, $digits: 0, $mode: round) {
	$n: 1;
	$unit: unit($number);
	$number: strip-unit($number);

	@if type-of($number) != number {
		//@warn '#{$number} is not a number.';
		@return $number;
	}

	@if type-of($digits) != number {
		//@warn '#{$digits} is not a number.';
		@return $number;
	} @else if not unitless($digits) {
		//@warn '#{$digits} has a unit.';
		@return $number;
	}

	@for $i from 1 through $digits {
		$n: $n * 10;
	}

	@if $mode == round {
		$number: round($number * $n) / $n;

		@if $unit {
			@return $number + $unit;
		} @else {
			@return $number;
		}
	} @else if $mode == ceil {
		$number: ceil($number * $n) / $n;

		@if $unit {
			@return $number + $unit;
		} @else {
			@return $number;
		}
	} @else if $mode == floor {
		$number: floor($number * $n) / $n;

		@if $unit {
			@return $number + $unit;
		} @else {
			@return $number;
		}
	} @else {
		//@warn '#{$mode} is undefined keyword.';
		@return $number;
	}
}

@function explode($string, $splitBy, $list: ()) {
	$index: str-index($string, $splitBy);

	@if $index {
		$chunk: str-slice($string, 1, $index - 1);
		$str: str-slice(
			$string,
			$index + str-length($splitBy),
			str-length($string) + 1
		);

		@if $chunk != "" {
			$list: append($list, $chunk, comma);
		}

		@return explode($str, $splitBy, $list);
	} @else {
		$str: str-slice($string, 1, str-length($string) + 1);

		@if $str != "" {
			$list: append($list, $str, comma);
		}

		@return $list;
	}
}

@function implode($list, $glue) {
	$str: "";
	$i: 1;
	$len: length($list);

	@each $chunk in $list {
		$str: $str + $chunk;

		@if $i < $len {
			$str: $str + $glue;
		}

		$i: $i + 1;
	}

	@return $str;
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@function list-pop($list) {
	$len: length($list) - 1;
	$separator: list-separator($list);
	$newlist: ();

	@for $i from 1 through $len {
		$newlist: append($newlist, nth($list, $i), $separator);
	}

	@return $newlist;
}

@function prepend($list, $value) {
	$separator: list_separator($list);
	$newlist: append((), $value, $separator);

	@for $i from 1 through length($list) {
		$newlist: append($newlist, nth($list, $i), $separator);
	}

	@return $newlist;
}

@function encode-svg($svg, $asURL: true) {
	$encoded: "";
	$slice: 2000;
	$index: 0;
	$loops: ceil(str-length($svg) / $slice);

	@for $i from 1 through $loops {
		$chunk: str-slice($svg, $index, $index + $slice - 1);
		$chunk: str-replace($chunk, '"', "'");
		$chunk: str-replace($chunk, "<", "%3C");
		$chunk: str-replace($chunk, ">", "%3E");
		$chunk: str-replace($chunk, "&", "%26");
		$chunk: str-replace($chunk, "#", "%23");
		$encoded: #{$encoded}#{$chunk};
		$index: $index + $slice;
	}

	@if $asURL {
		@return url("data:image/svg+xml;utf8,#{$encoded}");
	} @else {
		@return unquote("data:image/svg+xml;utf8,#{$encoded}");
	}
}

@function str-replace($string, $search, $replace) {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
			str-replace(
				str-slice($string, $index + str-length($search)),
				$search,
				$replace
			);
	}

	@return $string;
}

@function to-number($value) {
	@if type-of($value) == "number" {
		@return $value;
	}

	$result: 0;
	$digits: 0;
	$minus: str-slice($value, 1, 1) == "-";
	$numbers: (
		"0": 0,
		"1": 1,
		"2": 2,
		"3": 3,
		"4": 4,
		"5": 5,
		"6": 6,
		"7": 7,
		"8": 8,
		"9": 9,
	);

	@for $i from if($minus, 2, 1) through str-length($value) {
		$character: str-slice($value, $i, $i);

		@if not(index(map-keys($numbers), $character) or $character == ".") {
			@return to-length(
				if($minus, -$result, $result),
				str-slice($value, $i)
			);
		}

		@if $character == "." {
			$digits: 1;
		} @else if $digits == 0 {
			$result: $result * 10 + map-get($numbers, $character);
		} @else {
			$digits: $digits * 10;
			$result: $result + map-get($numbers, $character) / $digits;
		}
	}

	@return if($minus, -$result, $result);
}

@function to-length($value, $unit) {
	$units: (
		"px": 1px,
		"cm": 1cm,
		"mm": 1mm,
		"%": 1%,
		"ch": 1ch,
		"pc": 1pc,
		"in": 1in,
		"em": 1em,
		"rem": 1rem,
		"pt": 1pt,
		"ex": 1ex,
		"vw": 1vw,
		"vh": 1vh,
		"vmin": 1vmin,
		"vmax": 1vmax,
		"s": 1s,
		"ms": 1ms,
	);

	@return $value * map-get($units, $unit);
}

@function dec-to-bin($value) {
	$largest: 1;

	@while $largest <= $value {
		$largest: $largest * 2;
	}

	$result: 0;

	@while $largest >= 1 {
		@if $value - $largest >= 0 {
			$value: $value - $largest;
			$result: $result * 10 + 1;
		} @else {
			$result: $result * 10;
		}

		$largest: $largest / 2;
	}

	@return $result;
}

@function power($x, $y) {
	$ret: 1;

	@if $y > 0 {
		@for $i from 1 through $y {
			$ret: $ret * $x;
		}
	} @else {
		@for $i from $y to 0 {
			$ret: $ret / $x;
		}
	}

	@return $ret;
}

@function is-bit-set($x, $y) {
	@if $y > $x {
		//	@error "Parametr $bit nesmi byt vyssi nez parametr $mask";
		@return false;
	}

	$b: str-length(dec-to-bin($x) + unquote(""));
	$res: 0;

	@for $i from 0 through $b {
		$h: power(2, $i);
		$res: $res + $h * (floor($x / $h) % 2) * (floor($y / $h) % 2);
	}

	@return $res == $y;
}
