.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 2em;

	.list {
		padding: 0 0 0 2em;
	}

	&__item {
		margin: 0 0 .7em;
	}

	&__description {
		margin-top: .25em;
	}
}

.icon-links {
	.list {
		&__item {
			@include grid(600px 3 2);

			svg {
				max-width: 100%;
				height: auto;
				fill: $color-dark;
				transition: fill .3s ease-in-out;

				@include link-over(&) {
					fill: $color-brand;
				}
			}
		}

		&__link {
			@include flex(df, fdc, aic);
		}

		&__title {
			margin-top: 1rem;
		}
	}
}

.personal-data {
	//&__term{
	//    color: $default-document-link-color;
	//}
}

.org-contacts {
	&__contact {
		span:not(:first-of-type) {
			&::before {
				background-color: $default-document-link-color;
			}
		}
	}
}

.-custom-number-marker{
	ul{
		display: flex;
		flex-direction: column;
		padding-left: 0;
		gap: 1rem;
		li{
			display: flex;
			gap: 1rem;
			align-items: baseline;
			&::before{
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 42px;
				height: 42px;
				border: 2px dotted #bdbdbd;
				color: $color-dark;
				font-size: rem(18px);
				font-weight: 800;
			}
		}
		@for $i from 1 through 10 {
			li:nth-child(#{$i}) {
			  &::before {
				 content: "#{$i}";
			  }
			}
		}
	}
}

.-grid-list{
	ul{
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
		li{
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			figure{
				margin: 0;
			}
		}
		@include min(600px){
			grid-template-columns: repeat(2, 1fr);
			gap: 2rem;
		}
		@include min(1024px){
			gap: 4rem;
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&.-grid-list--one-collumn{
		ul{
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			padding-left: 0;
			li{
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				align-items: center;
    			text-align: center;
				figure{
					margin: 0;
				}
			}
			@include min(600px){
				gap: 2rem;
			}
			@include min(1024px){
				gap: 4rem;
			}
		}
	}
}
