
.contact-form {
	padding: 0;
	margin: 0;
	h2.element-type-heading{
		display: none!important;
	}

	.form__section {
		margin-bottom: 1em;
	}

	&__input,
	&__textarea {
		background-color: #f1f1f1;
		border-color: #f1f1f1;
		color: #838383;
		border-radius: 10px;
		padding: 1em 1.5em;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}

	.form__gdpr-agreement{
		//margin-left: auto;
	}

	&__button {
		border-radius: 10px;
		color: #ffffff;

		&.-primary {
			min-width: 250px;
    		//margin-left: auto;
			color: #ffffff;
			font-weight: normal;
			font-size: rem(18px);
		}
	}
}
