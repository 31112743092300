
.page-footer {
	text-align: center;
	background-color: $color-brand;
	color: #ffffff;
	padding: 4em 3% 3em;
	border-top: 1px solid #cd4649;
	.centered{
		@include min(950px){
			display: flex;
			gap: 0 2rem;
			.page-footer__webmaster{
				margin-left: auto;
			}
		}
	}
	@include link {
		color: #ffffff;
	}
}
