
.search {
	@include flex(df, ais, fg0);
    padding: 1em 0 1em;
    @include min(600px){
        padding: 1em 0 1em 1em;
        margin-left: auto;
        &__form {
            flex-grow: 0;
        }
        &__input{
            width: 13em;
        }
    }
    @include min(1024px){
        align-self: center;
    }
	&__form {
        display: flex;
        flex-grow: 1;
	}

	&__label {
        display: block;
	}

	&__input,
	&__button {
		border-radius: 4px;
	}

	&__input {
        height: 46px!important;
        flex-grow: 1;
        transition: border-color .15s ease;
        padding: 5px 5px 5px 1rem!important;
        background-position-x: 1rem!important;

		&:focus {
            border-color: seagreen;
            background: #ffffff!important;
		}
	}

	&__button {
		background-color: $color-secondary;
		color: $color-dark;
		margin-left: 1em;
		padding-top: 0.725em;
        padding-bottom: 0.725em;
        &[type=submit]{
            transition: background-color 0.5s ease;
            @include link-over(&){
                background-color: lightgrey;
            }
        }
    }
    .-hidden{
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
