$gte-text-color: #ffffff;
$gte-backgroud-color: $color-brand;

#google_translate_element {
	display: inline-block;
	position: relative;
    top: -1px;
    margin: 1em 0 1em 1em;
    align-self: center;

	&.field {
		background-color: $gte-backgroud-color;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		&,
		& * {
			font-size: rem(16px) !important;
		}

		.goog-te-gadget-icon{
			width: 44px;
			height: 44px;
			background-size: 100%;
			background-repeat: no-repeat;
		}

		.goog-te-menu-value {
			margin-right: 0;
			display: inline-block;
			text-decoration: none!important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: inline-block;
					color: $gte-text-color;
				}
				
				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0!important;
				}
				
				&:nth-of-type(3) {
					background: none;
					color: $gte-text-color!important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include max(599px) {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none!important;
	}
}