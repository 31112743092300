
body {
	font-family: $font-body;
}

@include headings(1, 6) {
	font-family: $font-body;
}

@include headings(1, 4) {
	font-weight: 800;
	line-height: 1.15;
	margin: 0 0 0 0;
	color: $color-tertiary;
}

@include headings(5, 6) {
	font-size: 700;
	margin: 0 0 .5em 0;

	* + & {
		margin-top: 1.65em;
	}
}
//Section headers
h3.-size-h2,
h2.element-type-heading{
	font-family: $font-headings;
	font-size: rem(52px);
	color: $color-tertiary;
	font-weight: 500;
}

.content .article__body h2::before{
	content: normal;
}

//Plain text header size
h3 {
	font-size: rem(28px);
}

h4 {
	font-size: rem(24px);
}

h5 {
	font-size: rem(20px);
}

h6 {
	font-size: rem(18px);
}

//Section headers at "Jak tvorime weby"
.article__section{
	h2.element-type-heading,
	h3.element-type-heading {
		font-size: rem(28px);
		font-weight: 800;
		font-family: $font-body;
	}

	h3.element-type-heading {
		margin-bottom: 2rem;

		&::before {
			display: block;
			font-weight: 800;
			color: $color-brand;
			font-size: 3.5rem;
			margin-bottom: 1rem;

			@include min(900px) {
				font-size: rem(78px);
			}
		}
	}
}


//section "Napiste nam"
.organisational-folder.-contact-us {
	h2.element-type-heading,
	.org-opening-hours__title.-size-h2 {
		font-size: rem(26px);
		font-family: $font-body;
		font-weight: 800;
		color: #383838;
	}
	.article-inline-headers h3.element-type-heading {
		font-size: rem(18px);
		font-weight: 700;
		font-family: inherit;
		color: inherit;
	}
}

@include link($visited: false) {
	color: $color-tertiary;
}

@include link-visited {
	color: lighten($color-tertiary, 10%);
}

@include link-over {
	text-decoration: none;
}
