[class*="lazy-"] {
	transition: filter .5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	display: block;
	background: #dedede;
	width: 100%;
	min-width: 240px;
	padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

html{
	line-height: 1.4;
}

body {
	background: #ffffff;
	&::before{
		position: absolute;
		z-index: -1;
		content: "";
		width: 100%;
		height: 30px;
		background: #ffffff url(/images/local/v-202503271058/header-bg-top-mobile.png) no-repeat 60% 100%;
	}

	@include min(600px){
		background: #ffffff url(/images/local/v-202503271058/header-bg-left.png) no-repeat 0% 200px;
		background-size: auto;
		&::before{
			content: normal;
		}
	}
	@include min(1024px){
		background-position: 0 0;
		background-size: 7%;
	}
	@include min(1700px){
		background-position: 0% 120px;
		background-size: auto;
	}
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}

.main-wrapper.centered {
	&.ot-10067,
	&.ot-10210{
		max-width: 100%;
		width: 100%;
		.main{
			max-width: 100%;
		}
	}
	&.ot-10083,
	&.ot-10282{
		max-width: 100%;
		width: 100%;
		padding-left: 3%;
		padding-right: 3%;
		overflow: hidden;
	}
	@include min(800px) {
		@include flex(df, fdrr);
		margin-bottom: 5rem;
	}
	@include min(1024px) {
		&.ot-10067,
		&.ot-10210{
			.main{
				&::before {
					position: absolute;
					right: 0;
					content: "";
					width: 315px;
					height: 833px;
					background: transparent url(/images/local/v-202503271058/web-making-bg.png) no-repeat center center;
					background-size: contain;
					z-index: -1;
					max-width: 16vw;
				}
			}
		}
	}
}

.main {
	padding-bottom: 0;

	@include min(800px) {
		padding-left: 4vw;
		flex-grow: 1;
		padding-bottom: 1rem;

		.-nosidebar & {
			padding-left: 0;
			max-width: 1100px;
			margin: 0 auto;
		}
		.ot-10200 &{
			max-width: 100%;
		}
	}
}

.textElm[contenteditable]{
	min-width: 14rem;
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.home {
	display: flex;
	flex-direction: column;
}

.sidebar {
	padding-top: 2em;
	padding-bottom: 2em;
	position: relative;
	.-nosidebar & {
		display: none;
	}

	@include min(800px) {
		//width: ratio($max-width, 480px);
		min-width: 300px;
		width: 300px;
		border-right: 1px solid #c4c4c4;
		flex-shrink: 0;

		&::after {
			content: normal;
		}
	}
	@include min(2000px){
		min-width: 340px;
		width: 340px;
	}
}

.content {
	max-width: 900px;
	margin-right: auto;
	.-nosidebar & {
		margin-left: auto;
		max-width: 100%;
	}
	.ot-10067 &,
	.ot-10210 &{
		max-width: 1920px;
	}
}

.section {
	padding: 0 0;

	.element-type-heading {
		font-size: rem(33px);
		font-weight: 500;
		color: $color-dark;
		margin-bottom: 0;
		@include min(600px) {
			font-size: 3.25rem;
		}
		@include min(1024px) {
			font-size: 4vw;
		}
		@include min(1650px) {
			font-size: rem(70px);
		}
	}
	@include min(#{$max-width}) {
		padding: 1.7em 0;
	}
}

.centered {
	max-width: $max-width;
	width: 90%;
	margin-left: auto;
	margin-right: auto;

	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}


.flair {
	//height: 0;
	//padding-bottom: ratio(1920px, 666px);
	background: #292a2d url(/images/local/v-202503271058/bg-board.jpg) no-repeat 50% 0 / 100% auto;
}

.more {
	clear: both;
	&__button {
		background-color: $color-brand;
		padding: 1.2em 2em;
		font-weight: 500;
		display: block;
		font-size: rem(18px);
		font-weight: 700;
		border-radius: 10px;
		min-width: 250px;
		text-align: center;
		@include min(800px){
			display: inline-block;
		}
	}
	&.-revert{
		@include link(".more__button") {
			background-color: $color-tertiary;
			color: #ffffff;
			text-decoration: none;
		}

		@include link-over(".more__button") {
			background-color: $color-brand;
		}
	}
}

@include link(".more__button") {
	color: #ffffff;
	text-decoration: none;
}

@include link-over(".more__button") {
	background-color: $color-tertiary;
}

.board {
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 3em;
		overflow: hidden;

		&__item {
			@include grid(650px 2);
			flex-grow: 0;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;

			> :only-child {
				flex-grow: 1;
			}
		}

		.board-card {
			flex-grow: 1;
		}
	}
}

.text-component {
	margin-bottom: 2em;
	overflow: hidden;
	font-size: rem(18px);
	font-weight: 500;
	@include headings(1, 6) {
		+ p {
			margin-top: .5em;
		}
	}
	p:first-child {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}

	.image-align-left,
	.image-align-right {
		margin: 0;

		img {
			@include min(500px) {
				max-width: 42.5%;
				&.-img--bigger{
					max-width: 50%;
				}
			}
		}
	}

	.image-align-left {
		img {
			float: left;
			margin: 0 1em .75em 0;
			&.-img--bigger{
				margin: 0 2em .75em 0;
			}
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-right {
		img {
			float: right;
			margin: 0 0 .75em 1em;
			&.-img--bigger{
				margin: 0 0 .75em 2em;
			}
		}

		>*:first-of-type img {
			clear: both;
		}
	}

	.image-align-center {
		text-align: center;
		img {
			vertical-align: middle;
			margin: 0 .5rem .75em;
			max-width: 100%;
		}
	}

	[contenteditable="true"] {
		.images-container,
		.image {
			@include link-over(&){
				img {
					box-shadow: 0 0 5px red;
				}
			}
		}
	}
	.images-container{
		@include link{
			text-decoration: none;
		}
	}
}

.section.certificates{
	border-top: 1px solid #cccccc;
	padding-top: 2rem;
}

.additional {
	background: $color-brand url(/images/local/v-202503271058/additional-bg-top.png) repeat-x 0 0;
	color: #ffffff;
	&__content {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 170px 0 20px;
		.ot-10067 &,
		.ot-10210 &{
			padding: 20px 0 20px;
		}
		@include min(600px){
			.ot-10067 &,
			.ot-10210 &{
				padding: 60px 0 20px;
			}
		}
		@include min(900px){
			padding: 240px 0 120px;
			flex-wrap: nowrap;
			.ot-10067 &,
			.ot-10210 &{
				padding: 120px 0 120px;
			}
		}
	}
	&__publish-bg-bottom{
		width: 100%;
	}
	&__logo{
		position: relative;
		margin-bottom: 1rem;
		@include min(1650px){
			left: -6rem;
		}
	}

	.additional-link-up{
		position: absolute;
		padding-top: 0.5rem;
		right: 0;
		bottom: 0;
		transform: translateY(50%);
		width: 132px;
		height: 81px;
		display: flex;
		font-size: rem(14px);
		align-items: center;
		justify-content: center;
		background: transparent url(/images/local/v-202503271058/trsatko.png) no-repeat center center;
		&::after{
			position: absolute;
			right: 1rem;
			top: 48%;
			content: "";
			display: inline-block;
			width: 16px;
			height: 11px;
			background: transparent url(/images/local/v-202503271058/main-nav-arrow.png) no-repeat left 50%;
			transform: rotate(-90deg) translateY(-50%);
			transition: background-position .1s ease-in;
		}
		@include link(&) {
			font-weight: 800;
			color: #3b3b3b;
			text-decoration: none;
		}
		@include link-over(&) {
			text-decoration: underline;
			&::after{
				background-position-x: -100%;
			}
		}
	}

	.element-type-heading {
		color: #ffffff;
		font-family: $font-body;
		font-size: rem(14px);
		font-weight: 800;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5em;
		border-bottom: 1px solid #cd4649;
	}

	&-other{
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		margin-left: auto;
		max-width: 1000px;
		align-self: center;
		flex-shrink: 0;
		flex-direction: column;
		&__section{
			flex-grow: 1;
			font-size: rem(18px);
			font-weight: 300;
			p{
				line-height: 2;
				padding-right: 1rem;
			}
		}
		@include min(650px){
			flex-direction: row;
		}
		@include min(900px){
			padding-left: 3rem;
		}
	}
	section.contact{
		padding-left: 0;
	}
	.contact{
		display: flex;
		flex-direction: column;
		@include min(400px){
			padding-left: 6rem;
		}
		@include min(900px){
			padding-left: 0;
		}
	}
	.social-networks {
		&__link {
			svg{
				fill: #ffffff;
			}
		}
	}

	@include link {
		color: #ffffff;
		font-weight: 700;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
}

.text-component{
	.highlightedBg{
		font-style: normal;
		font-weight: bold;
		color: $color-brand;
        background-color: transparent;
	}
}

.pgn__link svg{
	width: auto;
}
