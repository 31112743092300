
a:focus,
video:focus,
button:focus,
summary:focus {
	&.keyboard-focus {
		@extend %default-outline;
	}
}


//TODO: nahradit skript :focus-visible přepisem, až bude podpora dostatečná
