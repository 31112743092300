
.input {
	@extend %form-element-template;
	min-height: $form-item-standard-size;
    padding: .3em 1em;
    &.-inline{
        display: inline-block;
        width: auto;
        margin-right: .25em;
    }

	&.-main {
		min-height: $form-item-xl-size;
		font-size: 1.15em;
	}
}
